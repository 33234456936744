






import AuthService from '@/services/AuthService'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Policy extends Vue {
  private policy: string = ''

  created() {
    AuthService.getPolicy().then(res => {
      if (res.status === 200) {
        this.policy = res.data.content
      }
    })
  }
}
